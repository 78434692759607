<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <div class="d-flex align-items-center position-relative my-1">
          <input
            type="text"
            v-model="searchParams.search"
            v-on:keyup.enter="handleSearch"
            class="form-control form-control-solid w-250px me-2"
            :placeholder="shippingConstants.SHIPPING_METHOD_PLACEHOLDER"
          />
        </div>
        <el-tooltip class="box-item" effect="dark" :content="generalConstants.SEARCH" placement="top">
          <button type="button" @click.prevent="handleSearch" class="btn btn-icon btn-light btn-sm me-2"><i class="bi bi-search"></i></button>
        </el-tooltip>
        <el-tooltip class="box-item" effect="dark" :content="generalConstants.RESET" placement="top">
          <button type="button" @click.prevent="resetSearch" class="btn btn-icon btn-light btn-sm"><i class="bi bi-arrow-counterclockwise"></i></button>
        </el-tooltip>
      </div>
    </div>
    <div class="card-body pt-0">
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :rows-per-page="searchParams.recordsPerPage"
        :empty-table-text="noDataFound"
        :loading="loading"
        :current-page="1"
        :total="total"
        :enable-items-per-page-dropdown="true"
        ref="updateProps"
        @items-per-page-change="handleSizeChange"
      >
        <template v-slot:cell-shipping_method="{ row: data }">
          {{ data.shipping_method }}
        </template>
        <template v-slot:cell-shipping_type="{ row: data }">
          <span v-for="(type, index) in data.shipping_type" :key="index">
            <span class="badge badge-light-primary m-1"> {{ type.shipping_type }} </span>
          </span>
        </template>
        <template v-slot:cell-status="{ row: data }">
          <div v-if="permission.isEditAllowed ">
            <el-switch
              v-model="data.status"
              @change="updateStatus($event, data.shipping_method_id)"
              active-color="#13ce66"
              inactive-color="#ff4949"
            />
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template v-slot:cell-actions="{ row: data }">
          <el-tooltip class="box-item" effect="dark" :content="generalConstants.EDIT" placement="top" v-if="permission.isEditAllowed"> 
            <button type="button" @click.prevent="$router.push({ name: 'edit-shipping-method', params: { id: data.shipping_method_id } })" class="btn btn-icon btn-light btn-sm me-2">
              <i class="bi bi-pencil-square"></i>
            </button>
          </el-tooltip>
        </template>
      </Datatable>
    </div>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { notificationFire } from "@/composable/notification.js";
import { useRoute } from "vue-router";
import { formatText } from "@/core/helpers/common";
import globalConstant from "@/core/data/globalConstant.js";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";
import { Actions } from "@/store/enums/RememberSearchEnums";
import { useStore } from "vuex";

export default {
  name: "shipping-method-list",
  components: {
    Datatable,
  },
  setup() {
    const generalConstants = globalConstant.general;
    const shippingConstants = globalConstant.shippingMethod;
    const orderConstants = globalConstant.orders;
    const route = useRoute();
    const store = useStore();
    const moduleName = ref((route.meta.module) ? route.meta.module : '');
    const total = ref(0);
    const tableData = ref([]);
    const initData = ref([]);
    const loading = ref(false);
    const noDataFound = ref(generalConstants.LOADING);
    const updateProps = ref(null);
    const searchParams = reactive({
      search: store.getters.getSearch(moduleName.value),
      recordsPerPage: store.getters.getPerPage(moduleName.value),
      pageNub: store.getters.getPageNumber(moduleName.value)
    });
    const permission = reactive({
      isEditAllowed: false
    })
    //Set Table header data
    const tableHeader = ref([
      {
        name: generalConstants.SRNO,
        key: "index",
        sortable: false,
        width: "w-10px",
      },
      {
        name: orderConstants.SHIPPING_METHOD,
        key: "shipping_method",
        sortable: true,
        width: "w-100px",
      },
      {
        name: orderConstants.SHIPPING_TYPE,
        key: "shipping_type",
        sortable: true,
        width: "w-200px",
      },
      {
        name: generalConstants.STATUS,
        key: "status",
        width: "w-50px",
        sortable: false,
      },
      {
        name: generalConstants.ACTIONS,
        key: "actions",
        width: "w-100px",
        sortable: false,
        alignment: 'text-center'
      }
    ]);

    //Page size change event
    const handleSizeChange = (number) => {
      store.dispatch(Actions.PER_PAGE, {'module':moduleName.value,'value':number});
      searchParams.recordsPerPage = store.getters.getPerPage(moduleName.value);
      handleCurrentChange(1);
    };

    //Page change event
    const handleCurrentChange = (number) => {
      store.dispatch(Actions.PAGE_NUMBER, {'module':moduleName.value,'value':number});
      searchParams.pageNub = store.getters.getPageNumber(moduleName.value);
      getShippingMethod();
    };

    //Handle search event
    const handleSearch = () => {
      tableData.value = initData.value;
      if (searchParams.search !== "") {
        let results = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], searchParams.search)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value = results;
        if (results.length == 0) {
          updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, generalConstants.NO_DATA_FOUND);
        }
      }
      store.dispatch(Actions.SEARCH, {'module':moduleName.value,'value':searchParams.search});
			total.value = tableData.value.length;
			setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
    }

    //Search values in tableData
    const searchingFunc = (obj, value) => {
      for (let key in obj) {
        if (obj[key] && obj[key].length > 0 && !Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].toUpperCase().indexOf(value.toUpperCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    //Reset search value
    const resetSearch = () => {
      if(searchParams.search.length > 0){
        searchParams.search = "";
				store.dispatch(Actions.SEARCH, {'module':moduleName.value,'value':searchParams.search});
        handleSearch();
      }
    }

    //Get all shipping method
    const getShippingMethod = async () => {
      loading.value = true;
      await ApiService.query("shipping-method", { params: searchParams })
        .then(({ data }) => {
          if (data.data && data.data.length) {
            data.data.forEach((obj) => {
            //   obj.shipping_type = obj.shipping_type.type.split(',');
              obj.status = obj.status == 1 ? true : false;
            });
            tableData.value = data.data;
            initData.value = data.data;
            total.value = data.data.length;
            updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub);
          } else {
            tableData.value = initData.value = [];
            total.value = 0;
            updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, generalConstants.NO_DATA_FOUND);
          }
          setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
          loading.value = false;
          handleSearch();
        })
        .catch(({ err }) => {
          loading.value = false;
          tableData.value = [];
          total.value = 0;
        });
    };

    //Update shipping method status action from listing page
    const updateStatus = (status, id) => {
      ApiService.post("shipping-method/statusupdate", {
        status: status,
        id: id,
      })
        .then(({ data }) => {
          getShippingMethod();
          notificationFire(data.message, "success");
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let message = response.data.message;
            notificationFire(message, "error");
          }
        });
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{ name: formatText(route.name), active: true }]);
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("updateShippingMethod") || role == "Super Admin")
        permission.isEditAllowed = true;
      await getShippingMethod();
    });

    return {
      tableData,
      getShippingMethod,
      total,
      loading,
      updateStatus,
      resetSearch,
      handleSizeChange,
      handleCurrentChange,
      handleSearch,
      searchParams,
      tableHeader,
      noDataFound,
      updateProps,
      generalConstants,
      shippingConstants,
      permission
    };
  },
};
</script>
<style lang="scss">
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(2.1em + 1.1rem + 2px);
  width: calc(2.1em + 1.1rem + 2px);
}
.btn-list {
  margin-left: 10px !important;
}
</style>